import { createApi } from '@reduxjs/toolkit/dist/query/react';
import B2ChatClient, { apiResource, b2chatBaseQuery } from '@src/client';

export type GetWhatsAppSettingsResponse = {
  templateSettings: {
    allowCategoryChange: boolean;
  };
};

export type UpdateWhatsAppSettingsRequest = {
  templateSettings: {
    allowCategoryChange: boolean;
  };
};

export const whatsAppApi = createApi({
  reducerPath: 'whatsapp',
  baseQuery: b2chatBaseQuery,
  tagTypes: ['whatsapp-settings'],
  endpoints: ({ query, mutation }) => ({
    getWhatsAppSettings: query<GetWhatsAppSettingsResponse, void>({
      providesTags: ['whatsapp-settings'],
      query: () =>
        apiResource(
          B2ChatClient.resources.whatsapp.actions.retrieveSettings,
          {}
        ),
    }),
    updateWhatsAppSettings: mutation<void, UpdateWhatsAppSettingsRequest>({
      invalidatesTags: ['whatsapp-settings'],
      query: data =>
        apiResource(B2ChatClient.resources.whatsapp.actions.updateSettings, {
          data,
        }),
    }),
  }),
});

export const {
  useGetWhatsAppSettingsQuery,
  useLazyGetWhatsAppSettingsQuery,
  useUpdateWhatsAppSettingsMutation,
} = whatsAppApi;
